import validate from "/builds/3idprint/front/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/builds/3idprint/front/middleware/admin.global.ts";
import customer_45global from "/builds/3idprint/front/middleware/customer.global.ts";
import manifest_45route_45rule from "/builds/3idprint/front/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  customer_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/3idprint/front/middleware/auth.ts"),
  guest: () => import("/builds/3idprint/front/middleware/guest.ts")
}