import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const token = localStorage.getItem('token')
  const { setToken } = useAuthStore()

  if (token) {
    setToken(token)
  }
})
