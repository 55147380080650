import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const breakpoint = useBreakpoint()

  return {
    provide: {
      breakpoint
    }
  }
})
