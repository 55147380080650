import { GlobalThemeOverrides } from 'naive-ui'
import { TinyColor } from '@ctrl/tinycolor'
const primary = new TinyColor('#81a1c1')
const background = new TinyColor('#eceff4')
const success = new TinyColor('#a3be8c')
const info = new TinyColor('#88c0d0')
const warning = new TinyColor('#d08770')
const error = new TinyColor('#bf616a')
const divider = new TinyColor('#d8dee9')

export const extraColors = {
  background: background.toHexString(),
  divider: divider.toHexString(),
  dropzone: background.lighten(4).toHexString(),
  cal: {
    nonBusinessHours: background.setAlpha(0.45).toRgbString(),
    border: divider.setAlpha(0.45).toRgbString(),
    btn: primary.toRgbString(),
    btnHover: primary.darken(8).toRgbString()
  }
}
export const themeOverrides: GlobalThemeOverrides = {
  common: {
    // bodyColor: primary.toHexString(),
    primaryColor: primary.toHexString(),

    primaryColorHover: primary.darken(8).toHexString(),
    primaryColorPressed: primary.darken(8).toHexString(),
    successColor: success.toHexString(),
    successColorHover: success.darken(8).toHexString(),
    successColorPressed: success.darken(8).toHexString(),
    infoColor: info.toHexString(),
    infoColorHover: info.darken(8).toHexString(),
    infoColorPressed: info.darken(8).toHexString(),
    warningColor: warning.toHexString(),
    warningColorHover: warning.darken(8).toHexString(),
    warningColorPressed: warning.darken(8).toHexString(),
    errorColor: error.toHexString(),
    errorColorHover: error.darken(8).toHexString(),
    errorColorPressed: error.darken(8).toHexString(),
    hoverColor: '#d8dee9',
    dividerColor: '#d8dee9'
  },
  Layout: {
    siderColor: background.lighten(4).toHexString(),
    headerColor: background.lighten(4).toHexString()
  },
  Menu: {
    peers: {
      Tooltip: {
        peers: {
          Popover: {
            color: ' white'
          }
        }
      }
    }
  },
  Popover: {
    color: 'white',
    textColor: 'black'
  },
  Button: {
    // textColor: '#FF0000'
  },
  Progress: {
    // fillColor: primary.toHexString()
  },
  Select: {
    peers: {
      InternalSelection: {
        // textColor: '#FF0000'
      }
    }
  }
}
