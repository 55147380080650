// For Nuxt 3
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faFile,
  faHouse,
  faTimes,
  faRightToBracket,
  faUpload,
  faFileLines,
  faFileArrowUp,
  faCartShopping,
  faCartPlus,
  faLayerGroup,
  faList,
  faListOl,
  faListCheck,
  faUserTie,
  faFileInvoice,
  faRightFromBracket,
  faTriangleExclamation,
  faCircleInfo,
  faCalculator,
  faBars,
  faEuroSign,
  faMinus,
  faPlus,
  faHandPointRight,
  faDeleteLeft,
  faFileZipper,
  faEnvelopesBulk,
  faUsers,
  faUser,
  faGears,
  faFileExcel,
  faMoneyCheckDollar,
  faAnglesRight,
  faAnglesLeft,
  faCircleQuestion,
  faObjectGroup,
  faCalendarDays,
  faFloppyDisk,
  faCopy,
  faInfo,
  faTrashCan,
  faRecycle
} from '@fortawesome/free-solid-svg-icons'

import {
  faSquareMinus
} from '@fortawesome/free-regular-svg-icons'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add({
  faRightToBracket,
  faUpload,
  faHouse,
  faTimes,
  faFile,
  faFileLines,
  faFileArrowUp,
  faCartShopping,
  faCartPlus,
  faLayerGroup,
  faList,
  faListOl,
  faListCheck,
  faFileInvoice,
  faUserTie,
  faRightFromBracket,
  faTriangleExclamation,
  faCircleInfo,
  faCalculator,
  faBars,
  faEuroSign,
  faMinus,
  faPlus,
  faHandPointRight,
  faDeleteLeft,
  faFileZipper,
  faEnvelopesBulk,
  faUsers,
  faUser,
  faGears,
  faFileExcel,
  faMoneyCheckDollar,
  faAnglesRight,
  faAnglesLeft,
  faCircleQuestion,
  faObjectGroup,
  faCalendarDays,
  faFloppyDisk,
  faCopy,
  faInfo,
  faTrashCan,
  faRecycle,
  faSquareMinus
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})
