import {
  BroadcastingRequest,
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  ResendVerificationRequest,
  ResetPasswordRequest, UserModel
} from '~/types'
import { endpoints, request } from '~/utils/api'

export const login = (credentials: LoginRequest) => request.post<LoginResponse>(endpoints.login, credentials)
export const forgotPwd = (data: ForgotPasswordRequest) => request.post<{}>(endpoints.forgot, data)
export const resetPwd = (data: ResetPasswordRequest) => request.post<{}>(endpoints.reset, data)
export const register = (data: RegisterRequest) => request.post<{}>(endpoints.register, data)
export const me = () => request.get<UserModel>(endpoints.me)
export const verification = (email: string, key: string) => request.post<LoginResponse>(endpoints.activation + `/${email}/${key}`, {})
export const resendVerificationLink = (data: ResendVerificationRequest) => request.post<{}>(endpoints.resendVerificationLink, data)
export const broadcasting = (data:BroadcastingRequest) => request.post<{}>(endpoints.broadcasting, data)
