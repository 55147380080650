import breakpointHelper from 'breakpoint-helper'

import { ref, onBeforeUnmount } from 'vue'
import { Breakpoints } from '~/types'

type listenerProp = {
  on: () => void
  off: () => void
}
export default function () {
  const { isMatching: baseMatching, listen } = breakpointHelper({
    xs: '375px',
    sm: '640px',
    md: '1024px',
    lg: '1280px',
    xl: '1536px',
    xxl: '1920px'
  })
  const listeners:listenerProp[] = []

  const isMatching = (bp:Breakpoints):boolean => baseMatching(bp)

  const isExtraSmallRef = ref<boolean>()
  const isSmallRef = ref<boolean>()
  const isMediumRef = ref<boolean>()
  const isLargeRef = ref<boolean>()

  const isNone = computed(() => !isExtraSmallRef.value && !isSmallRef.value && !isMediumRef.value && !isLargeRef.value)
  const isExtraSmall = computed(() => (isExtraSmallRef.value && !isSmallRef.value) || isNone.value)
  const isSmall = computed(() => isSmallRef.value && !isMediumRef.value)
  const isMedium = computed(() => isMediumRef.value && !isLargeRef.value)
  const isLarge = computed(() => isLargeRef.value)
  const isBelowMd = computed(() => isSmall.value || isExtraSmall.value || isNone.value)
  const isBelowSm = computed(() => isExtraSmall.value || isNone.value)

  listeners.push(listen('xs', ({ matches }) => { isExtraSmallRef.value = matches }))
  listeners.push(listen('sm', ({ matches }) => { isSmallRef.value = matches }))
  listeners.push(listen('md', ({ matches }) => { isMediumRef.value = matches }))
  listeners.push(listen('lg', ({ matches }) => { isLargeRef.value = matches }))

  const unMount = () => {
    for (const listener of listeners) {
      listener.off()
    }
  }
  return {
    isBelowMd,
    isExtraSmall,
    isSmall,
    isMedium,
    isBelowSm,
    isMatching,
    isLarge,
    listen,
    isNone,
    unMount
  }
}
