export const endpoints = {
  login: '/auth/login',
  register: '/auth/register',
  activation: '/auth/activation',
  forgot: '/auth/password/forgot',
  reset: '/auth/password/reset',
  me: '/auth/me',
  resendVerificationLink: '/auth/activation/resend',
  subscriptions: '/subscriptions',
  userFile: '/users/files',
  user: '/users',
  userOrder: '/users/orders',
  userSubscriptions: '/users/subscriptions',
  broadcasting: '/broadcasting/auth',
  printOptions: '/print-options',
  config: '/configurations',
  calendarEvents: '/calendars/events',
  adminOrders: '/admin/orders'
}
