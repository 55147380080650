import axios, { AxiosError } from 'axios'
// @ts-ignore
import { defineNuxtPlugin, navigateTo } from '#app'
import { localePath } from 'vue-i18n-routing'
import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { unsetToken } = useAuthStore()

  axios.defaults.baseURL = config.public.apiUrl
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['Accept-Language'] = window?.navigator.language
  axios.defaults.headers.common['X-Tenant'] = window?.location.host || ''

  axios.interceptors.request.use((config) => {
    const { token } = useAuthStore()
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  axios.interceptors.response.use(
    res => res,
    (error: AxiosError) => {
      const { data, status, config } = error.response!
      switch (status) {
      //   case 400:
      //     console.error(data)
      //     break
      //
        case 401:
          unsetToken()
          useRouter().push(nuxtApp.$localePath({ name: 'auth-login' }))
          break

      //   case 404:
      //     // 404
      //     break
      //
      //   case 500:
      //     console.error('/server-error')
      //     break
      }
      return Promise.reject(error)
    }
  )
  return {
    provide: {
      axios
    }
  }
})
