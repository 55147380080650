import { navigateTo } from '#app'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const store = useAuthStore()
  const { isAdmin } = storeToRefs(store)
  const localePath = useLocalePath()

  if (to.name.includes('account')) {
    return
  }
  if (isAdmin.value && !to.name.includes('admin')) {
    return navigateTo(localePath({ name: 'admin' }))
  }
})
