<script lang="ts" setup>
import { ref } from 'vue'
import { GlobalTheme, NConfigProvider, NNotificationProvider, NGlobalStyle } from 'naive-ui'
import { themeOverrides } from '~/theme'
const theme = ref<GlobalTheme | null>(null)

useHead({
  title: '3IDPrint'
})
const { $breakpoint } = useNuxtApp()

onBeforeUnmount(() => {
  $breakpoint.unMount()
})

</script>
<template>
  <NConfigProvider :theme="theme" :theme-overrides="themeOverrides">
    <NNotificationProvider>
      <NGlobalStyle />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </NNotificationProvider>
  </NConfigProvider>
</template>

<style>
:deep(.n-dropdown-option-body__label) {
  display: flex!important;
  align-items: center!important;
}

</style>
<style lang="scss">
html,body, #__nuxt, #__layout, .n-config-provider, .full{
  padding: 0;
    height:100%!important;
    width: 100%!important;
}
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.5rem);
}

.n-button {
  text-transform: uppercase;
}
</style>
