import PusherJS, { ChannelAuthorizationCallback } from 'pusher-js'
import { broadcasting } from '~/utils/api'

export default defineNuxtPlugin(() => {
  const client = getClient()

  return {
    provide: {
      pusher: client
    }
  }
})
const authorizer = (channel, options) => {
  return {
    authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
      return broadcasting({
        socket_id: socketId,
        channel_name: channel.name
      }).then((data) => {
        callback(false, data)
      }).catch((error) => {
        callback(true, error)
      })
    }
  }
}

const getClient = () => {
  const config = useRuntimeConfig()
  return new PusherJS(config.public.pusherKey, {
    cluster: 'mt1',
    forceTLS: false,
    disableStats: true,
    wsHost: config.public.pusherHost,
    wsPort: parseInt(config.public.pusherPort, 10),
    wssPort: parseInt(config.public.pusherPort, 10),
    enabledTransports: ['ws', 'wss'],
    authorizer
  })
}
