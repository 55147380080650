import revive_payload_client_4sVQNw7RlN from "/builds/3idprint/front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/3idprint/front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/3idprint/front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/3idprint/front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/3idprint/front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/3idprint/front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/3idprint/front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_TNUJsc4G2r from "/builds/3idprint/front/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/composition.mjs";
import i18n_wvJtu1yLPy from "/builds/3idprint/front/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/3idprint/front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/3idprint/front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import i18n_VfGcjrvSkj from "/builds/3idprint/front/plugins/i18n.ts";
import breakpoint_JVnKhWJKDQ from "/builds/3idprint/front/plugins/breakpoint.ts";
import emmiter_LgOnDOdekF from "/builds/3idprint/front/plugins/emmiter.ts";
import pusher_T6fDwDIeLY from "/builds/3idprint/front/plugins/pusher.ts";
import auth_vT9JWWT9pN from "/builds/3idprint/front/plugins/auth.ts";
import fontAwesome_OdbcMzCLm1 from "/builds/3idprint/front/plugins/fontAwesome.ts";
import axios_QMFgzss1s4 from "/builds/3idprint/front/plugins/axios.ts";
import vee_validate_KcKlKmvCrJ from "/builds/3idprint/front/plugins/vee-validate.ts";
import sentry_3AyO8nEfhE from "/builds/3idprint/front/plugins/sentry.ts";
import i18n_config_chpUfOMRWg from "/builds/3idprint/front/plugins/i18n.config.ts";
import naive_ui_cjnU5TlW5h from "/builds/3idprint/front/plugins/naive-ui.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_TNUJsc4G2r,
  i18n_wvJtu1yLPy,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  i18n_VfGcjrvSkj,
  breakpoint_JVnKhWJKDQ,
  emmiter_LgOnDOdekF,
  pusher_T6fDwDIeLY,
  auth_vT9JWWT9pN,
  fontAwesome_OdbcMzCLm1,
  axios_QMFgzss1s4,
  vee_validate_KcKlKmvCrJ,
  sentry_3AyO8nEfhE,
  i18n_config_chpUfOMRWg,
  naive_ui_cjnU5TlW5h
]