import { setup } from '@css-render/vue3-ssr'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(({ vueApp, ssrContext }) => {
  if (process.server) {
    const { collect } = setup(vueApp)
    const originalRenderMeta = ssrContext?.renderMeta

    if (!ssrContext) {
      return
    }

    ssrContext.renderMeta = () => {
      if (!originalRenderMeta) {
        return {
          headTags: collect()
        }
      }
      const originalMeta = originalRenderMeta()
      if ('then' in originalMeta) {
        return originalMeta.then((resolvedOriginalMeta) => {
          return {
            ...resolvedOriginalMeta,
            headTags: resolvedOriginalMeta.headTags + collect()
          }
        })
      } else {
        return {
          ...originalMeta,
          headTags: originalMeta.headTags + collect()
        }
      }
    }
  }
})
