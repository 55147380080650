import { configure } from 'vee-validate'

export default defineNuxtPlugin((nuxtApp) => {
  configure({
    generateMessage: (context) => {
      return `The field ${context.field} is invalid`
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnInput: false,
    validateOnModelUpdate: false
  })
})
