import { defineStore } from 'pinia'
import { UserModel } from '~/types'
export const useAuthStore = defineStore('auth', () => {
  const token = ref()
  const user = ref<UserModel>()

  const setToken = (value: string) => {
    token.value = value
    localStorage.setItem('token', value)
  }
  const setUser = (value: UserModel) => {
    user.value = value
    localStorage.setItem('user', JSON.stringify(value))
  }

  const unsetToken = () => {
    token.value = null
    localStorage.removeItem('token')
  }

  const parsedToken = computed(() => {
    if (!token.value) { return }
    const base64Url = token.value.split('.')[1]
    if (!base64Url) { return }
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    return JSON.parse(decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join('')))
  })

  const isAdmin = computed(() => parsedToken?.value?.roles?.includes('admin') || false)

  return { token, setToken, parsedToken, unsetToken, setUser, user, isAdmin }
})
