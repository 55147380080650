import { default as index3paXTvZmfnMeta } from "/builds/3idprint/front/pages/account/index.vue?macro=true";
import { default as indexy0nTIEg2jYMeta } from "/builds/3idprint/front/pages/admin/config/calendar/index.vue?macro=true";
import { default as indexeF8TOenvNNMeta } from "/builds/3idprint/front/pages/admin/config/invoice/index.vue?macro=true";
import { default as index9RpuovBOybMeta } from "/builds/3idprint/front/pages/admin/config/mail/index.vue?macro=true";
import { default as _91group_932uLMq30dL3Meta } from "/builds/3idprint/front/pages/admin/config/print-options/[group].vue?macro=true";
import { default as index4dCIo5Q0NyMeta } from "/builds/3idprint/front/pages/admin/config/print-options/index.vue?macro=true";
import { default as indexP089nlrkgkMeta } from "/builds/3idprint/front/pages/admin/config/subscriptions/index.vue?macro=true";
import { default as index8LQF2ymk3sMeta } from "/builds/3idprint/front/pages/admin/index.vue?macro=true";
import { default as indexWi6Kc5Gu9cMeta } from "/builds/3idprint/front/pages/admin/orders/[id]/index.vue?macro=true";
import { default as indexvk1Fv2FSphMeta } from "/builds/3idprint/front/pages/admin/orders/index.vue?macro=true";
import { default as indexOBovHFi0S0Meta } from "/builds/3idprint/front/pages/admin/subscriptions/[id]/index.vue?macro=true";
import { default as indexOh9VbeRWDhMeta } from "/builds/3idprint/front/pages/admin/subscriptions/index.vue?macro=true";
import { default as indexTb5wZomcmtMeta } from "/builds/3idprint/front/pages/admin/users/[id]/index.vue?macro=true";
import { default as indexyVJNzCkN0tMeta } from "/builds/3idprint/front/pages/admin/users/index.vue?macro=true";
import { default as loginabaOt1kr0xMeta } from "/builds/3idprint/front/pages/auth/login.vue?macro=true";
import { default as forgot5cP3UbvuInMeta } from "/builds/3idprint/front/pages/auth/password/forgot.vue?macro=true";
import { default as _91token_93wpTkC52cQFMeta } from "/builds/3idprint/front/pages/auth/password/reset/[token].vue?macro=true";
import { default as registerMbiarF8B0iMeta } from "/builds/3idprint/front/pages/auth/register.vue?macro=true";
import { default as _91key_93taCPyhen3yMeta } from "/builds/3idprint/front/pages/auth/verification/[email]/[key].vue?macro=true";
import { default as authYiBTvmkuRfMeta } from "/builds/3idprint/front/pages/auth.vue?macro=true";
import { default as indexWTAOR0z2qSMeta } from "/builds/3idprint/front/pages/files/index.vue?macro=true";
import { default as indexY7YlgTinUqMeta } from "/builds/3idprint/front/pages/index.vue?macro=true";
import { default as calendarp5LlFiD1xJMeta } from "/builds/3idprint/front/pages/orders/calendar.vue?macro=true";
import { default as indexvv2Kw9XvflMeta } from "/builds/3idprint/front/pages/orders/index.vue?macro=true";
import { default as newkF6hzeg1s3Meta } from "/builds/3idprint/front/pages/orders/new.vue?macro=true";
import { default as indexlrxIBf7gzcMeta } from "/builds/3idprint/front/pages/subscriptions/index.vue?macro=true";
import { default as newtofZqSImYlMeta } from "/builds/3idprint/front/pages/subscriptions/new.vue?macro=true";
export default [
  {
    name: index3paXTvZmfnMeta?.name ?? "account___fr",
    path: index3paXTvZmfnMeta?.path ?? "/account",
    meta: index3paXTvZmfnMeta || {},
    alias: index3paXTvZmfnMeta?.alias || [],
    redirect: index3paXTvZmfnMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexy0nTIEg2jYMeta?.name ?? "admin-config-calendar___fr",
    path: indexy0nTIEg2jYMeta?.path ?? "/admin/config/calendar",
    meta: indexy0nTIEg2jYMeta || {},
    alias: indexy0nTIEg2jYMeta?.alias || [],
    redirect: indexy0nTIEg2jYMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/config/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexeF8TOenvNNMeta?.name ?? "admin-config-invoice___fr",
    path: indexeF8TOenvNNMeta?.path ?? "/admin/config/invoice",
    meta: indexeF8TOenvNNMeta || {},
    alias: indexeF8TOenvNNMeta?.alias || [],
    redirect: indexeF8TOenvNNMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/config/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: index9RpuovBOybMeta?.name ?? "admin-config-mail___fr",
    path: index9RpuovBOybMeta?.path ?? "/admin/config/mail",
    meta: index9RpuovBOybMeta || {},
    alias: index9RpuovBOybMeta?.alias || [],
    redirect: index9RpuovBOybMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/config/mail/index.vue").then(m => m.default || m)
  },
  {
    name: _91group_932uLMq30dL3Meta?.name ?? "admin-config-print-options-group___fr",
    path: _91group_932uLMq30dL3Meta?.path ?? "/admin/config/print-options/:group()",
    meta: _91group_932uLMq30dL3Meta || {},
    alias: _91group_932uLMq30dL3Meta?.alias || [],
    redirect: _91group_932uLMq30dL3Meta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/config/print-options/[group].vue").then(m => m.default || m)
  },
  {
    name: index4dCIo5Q0NyMeta?.name ?? "admin-config-print-options___fr",
    path: index4dCIo5Q0NyMeta?.path ?? "/admin/config/print-options",
    meta: index4dCIo5Q0NyMeta || {},
    alias: index4dCIo5Q0NyMeta?.alias || [],
    redirect: index4dCIo5Q0NyMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/config/print-options/index.vue").then(m => m.default || m)
  },
  {
    name: indexP089nlrkgkMeta?.name ?? "admin-config-subscriptions___fr",
    path: indexP089nlrkgkMeta?.path ?? "/admin/config/subscriptions",
    meta: indexP089nlrkgkMeta || {},
    alias: indexP089nlrkgkMeta?.alias || [],
    redirect: indexP089nlrkgkMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/config/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: index8LQF2ymk3sMeta?.name ?? "admin___fr",
    path: index8LQF2ymk3sMeta?.path ?? "/admin",
    meta: index8LQF2ymk3sMeta || {},
    alias: index8LQF2ymk3sMeta?.alias || [],
    redirect: index8LQF2ymk3sMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexWi6Kc5Gu9cMeta?.name ?? "admin-orders-id___fr",
    path: indexWi6Kc5Gu9cMeta?.path ?? "/admin/orders/:id()",
    meta: indexWi6Kc5Gu9cMeta || {},
    alias: indexWi6Kc5Gu9cMeta?.alias || [],
    redirect: indexWi6Kc5Gu9cMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvk1Fv2FSphMeta?.name ?? "admin-orders___fr",
    path: indexvk1Fv2FSphMeta?.path ?? "/admin/orders",
    meta: indexvk1Fv2FSphMeta || {},
    alias: indexvk1Fv2FSphMeta?.alias || [],
    redirect: indexvk1Fv2FSphMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexOBovHFi0S0Meta?.name ?? "admin-subscriptions-id___fr",
    path: indexOBovHFi0S0Meta?.path ?? "/admin/subscriptions/:id()",
    meta: indexOBovHFi0S0Meta || {},
    alias: indexOBovHFi0S0Meta?.alias || [],
    redirect: indexOBovHFi0S0Meta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/subscriptions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOh9VbeRWDhMeta?.name ?? "admin-subscriptions___fr",
    path: indexOh9VbeRWDhMeta?.path ?? "/admin/subscriptions",
    meta: indexOh9VbeRWDhMeta || {},
    alias: indexOh9VbeRWDhMeta?.alias || [],
    redirect: indexOh9VbeRWDhMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: indexTb5wZomcmtMeta?.name ?? "admin-users-id___fr",
    path: indexTb5wZomcmtMeta?.path ?? "/admin/users/:id()",
    meta: indexTb5wZomcmtMeta || {},
    alias: indexTb5wZomcmtMeta?.alias || [],
    redirect: indexTb5wZomcmtMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyVJNzCkN0tMeta?.name ?? "admin-users___fr",
    path: indexyVJNzCkN0tMeta?.path ?? "/admin/users",
    meta: indexyVJNzCkN0tMeta || {},
    alias: indexyVJNzCkN0tMeta?.alias || [],
    redirect: indexyVJNzCkN0tMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: authYiBTvmkuRfMeta?.name ?? "auth___fr",
    path: authYiBTvmkuRfMeta?.path ?? "/auth",
    children: [
  {
    name: loginabaOt1kr0xMeta?.name ?? "auth-login___fr",
    path: loginabaOt1kr0xMeta?.path ?? "login",
    meta: loginabaOt1kr0xMeta || {},
    alias: loginabaOt1kr0xMeta?.alias || [],
    redirect: loginabaOt1kr0xMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: forgot5cP3UbvuInMeta?.name ?? "auth-password-forgot___fr",
    path: forgot5cP3UbvuInMeta?.path ?? "password/forgot",
    meta: forgot5cP3UbvuInMeta || {},
    alias: forgot5cP3UbvuInMeta?.alias || [],
    redirect: forgot5cP3UbvuInMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: _91token_93wpTkC52cQFMeta?.name ?? "auth-password-reset-token___fr",
    path: _91token_93wpTkC52cQFMeta?.path ?? "password/reset/:token()",
    meta: _91token_93wpTkC52cQFMeta || {},
    alias: _91token_93wpTkC52cQFMeta?.alias || [],
    redirect: _91token_93wpTkC52cQFMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/auth/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: registerMbiarF8B0iMeta?.name ?? "auth-register___fr",
    path: registerMbiarF8B0iMeta?.path ?? "register",
    meta: registerMbiarF8B0iMeta || {},
    alias: registerMbiarF8B0iMeta?.alias || [],
    redirect: registerMbiarF8B0iMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91key_93taCPyhen3yMeta?.name ?? "auth-verification-email-key___fr",
    path: _91key_93taCPyhen3yMeta?.path ?? "verification/:email()/:key()",
    meta: _91key_93taCPyhen3yMeta || {},
    alias: _91key_93taCPyhen3yMeta?.alias || [],
    redirect: _91key_93taCPyhen3yMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/auth/verification/[email]/[key].vue").then(m => m.default || m)
  }
],
    meta: authYiBTvmkuRfMeta || {},
    alias: authYiBTvmkuRfMeta?.alias || [],
    redirect: authYiBTvmkuRfMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: indexWTAOR0z2qSMeta?.name ?? "files___fr",
    path: indexWTAOR0z2qSMeta?.path ?? "/files",
    meta: indexWTAOR0z2qSMeta || {},
    alias: indexWTAOR0z2qSMeta?.alias || [],
    redirect: indexWTAOR0z2qSMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/files/index.vue").then(m => m.default || m)
  },
  {
    name: indexY7YlgTinUqMeta?.name ?? "index___fr",
    path: indexY7YlgTinUqMeta?.path ?? "/",
    meta: indexY7YlgTinUqMeta || {},
    alias: indexY7YlgTinUqMeta?.alias || [],
    redirect: indexY7YlgTinUqMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: calendarp5LlFiD1xJMeta?.name ?? "orders-calendar___fr",
    path: calendarp5LlFiD1xJMeta?.path ?? "/orders/calendar",
    meta: calendarp5LlFiD1xJMeta || {},
    alias: calendarp5LlFiD1xJMeta?.alias || [],
    redirect: calendarp5LlFiD1xJMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/orders/calendar.vue").then(m => m.default || m)
  },
  {
    name: indexvv2Kw9XvflMeta?.name ?? "orders___fr",
    path: indexvv2Kw9XvflMeta?.path ?? "/orders",
    meta: indexvv2Kw9XvflMeta || {},
    alias: indexvv2Kw9XvflMeta?.alias || [],
    redirect: indexvv2Kw9XvflMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: newkF6hzeg1s3Meta?.name ?? "orders-new___fr",
    path: newkF6hzeg1s3Meta?.path ?? "/orders/new",
    meta: newkF6hzeg1s3Meta || {},
    alias: newkF6hzeg1s3Meta?.alias || [],
    redirect: newkF6hzeg1s3Meta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/orders/new.vue").then(m => m.default || m)
  },
  {
    name: indexlrxIBf7gzcMeta?.name ?? "subscriptions___fr",
    path: indexlrxIBf7gzcMeta?.path ?? "/subscriptions",
    meta: indexlrxIBf7gzcMeta || {},
    alias: indexlrxIBf7gzcMeta?.alias || [],
    redirect: indexlrxIBf7gzcMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: newtofZqSImYlMeta?.name ?? "subscriptions-new___fr",
    path: newtofZqSImYlMeta?.path ?? "/subscriptions/new",
    meta: newtofZqSImYlMeta || {},
    alias: newtofZqSImYlMeta?.alias || [],
    redirect: newtofZqSImYlMeta?.redirect || undefined,
    component: () => import("/builds/3idprint/front/pages/subscriptions/new.vue").then(m => m.default || m)
  }
]