import { navigateTo } from '#app'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware((to) => {
  const store = useAuthStore()

  const { isAdmin } = storeToRefs(store)
  const localePath = useLocalePath()

  if (!isAdmin.value && to.name.includes('admin')) {
    return navigateTo(localePath({ name: 'index' }))
  }
})
