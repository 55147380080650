import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { endpoints } from './endpoints'

export * from './auth'
export * from './userFile'
export * from './userOrder'
export * from './userSubscription'
export * from './subscription'
export * from './user'
export { endpoints } from './endpoints'

export const groupedPrintOptions = () => request.get(endpoints.printOptions + '/groups')
export const getConfig = config => request.get(endpoints.config + `/${config}`)
export const getCalendarEvents = data => request.post(endpoints.calendarEvents, data)

const responseBody = <T>(response: AxiosResponse<T>) => camelcaseKeys(response.data, { deep: true }) as T

export const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  download: <T>(url: string) => axios.get<T>(url, { responseType: 'blob' }).then((response: AxiosResponse<T>) => response.data),
  delete: <T>(url: string, id: any) => axios.delete<T>(`${url}/${id}`).then(responseBody),
  post: <T>(url: string, body: {}, options?: {}) =>
    axios.post<T>(url, snakecaseKeys(body), options).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(url, body).then(responseBody)
}
